import React from "react";
import styled from "styled-components";
import video from "../assests/video/Video.mp4";
import video2 from "../assests/video/Art_Reveal_Mobile_Comp.mp4";
import gif from '../assests/video/mobileios.gif'
function Video() {
  return (
    <Container>
      <video className="big-screen" autoPlay muted loop src={video}></video>
      <img width={'100%'} className="small-screen" src={gif} alt="" />
      {/* <video
        className="small-screen"
        preload="metadata"
        width={"100%"}
        autoPlay
        muted
        loop
        src={video2}
      ></video> */}
    </Container>
  );
}

export default Video;

const Container = styled.div`
  min-height: 100vh;
  width: 100%;

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .small-screen {
    display: none;
  }

  @media (max-width: 560px) {
    min-height: auto;
    /* height: 0vh; */
    .big-screen {
      display: none;
    }
    .small-screen {
      display: flex;
    }
  }
`;
