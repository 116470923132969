import React from 'react'
import styled from 'styled-components'

function Footer() {
  return (
    <Container>
        <Divider />
        <Main>
            <Heading>Join Our Group of</Heading>
            <img className='misfits-logo' src="/assests/RGB_Misfit_Logo.png" alt="" />
            <Desc>Get involved with our community on Twitter and Discord - Misfits from all walks of life are welcome! </Desc>
            <Social>FOLLOW US ON</Social>
            <SocialMedia>
                <a href="https://discord.gg/FVeUz9M67m" target={'_blank'}>
                    <img src="/assests/Discord.png" alt="Discord" />
                </a>
                <a href="https://twitter.com/MisfitsOrigins" target={'_blank'}>
                    <img src="/assests/Twitter.png" alt="Twitter" />
                </a>
            </SocialMedia>
        </Main>
    </Container>
  )
}

export default Footer

const Container = styled.div`
    max-width: 90vw;
    margin: 0 auto;
`

const Divider = styled.div`
    border: 0.4vh solid #FFDC00;
    @media(max-width: 460px) {
        border: 4px solid #FFDC00;
    }
`

const Main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 34px 0;

    .misfits-logo {
        padding: 0.8vh 0;
        min-width: 260px;
        width: 14vw;
    }
`

const Heading = styled.div`
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4vh;
    line-height: 3vh;
    text-align: center;
    color: #FFFFFF;
`

const Desc = styled.div`

    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 2vh;
    line-height: 2.3vh;
    color: #FFFFFF;
    padding: 1.6vh 0;
    text-align: center;
    @media(max-width: 460px) {
        font-size: 16px;
        line-height: 20px;
    }
`

const Social = styled.div`
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4vh;
    line-height: 2.9vh;
    color: #FFFFFF;
    padding: 16px 0;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 0.2vh;
        bottom: 1.0vh;
        background-color: #FFDC00;
    }
`

const SocialMedia = styled.div`
    display: flex;
    a:first-child {
        margin-right: 8px;
    }

    img {
        min-width: 30px;
        width: 2vw;
        min-height: 32.18px;
        height: 3.2vh;
        object-fit: contain;
    }
`