import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const cardList = [
    {
        title: 'PHASE 1',
        desc: [
            {
                title: 'Set-up a DAO - ',
                content: 'Every NFT held represents one vote in the future decision-making process and to vote on the proposals put forward by the DAO. '
            },
            {
                title: "Physical Art Giveaways - ",
                content: "Holders stand a chance to win physical artwork done by our artist, that will be shipped to the winners home address."
            },
            {
                title: "Misfits Care Initiative #1 – ",
                content: "A percentage of sales will go into the Misfits Care Initiative Fund. The first initiative under this program will involve onboarding real-world physical art creators into the NFT space. We will help them get set up, educate them about the world of NFTs and assist them in launching their first NFT art collection. Our holders will get exclusive access to these collections before it goes on sale to the public."
            },
            {
                title: "The Choice - ",
                content: "At the end of Scene 1, The Misfits receive a mysterious package that is about to change their lives. Each NFT holder gets to choose 1 out of 2 items, that will be airdropped to their wallet. This choice is completely free and will dictate how Scene 2 of the storyline progresses for your respective Misfit. If you hold 2 or more Misfits, the choice will have to be made for each individual Misfit that you hold. (More details announced soon)"
            }
        ]
    },
    {
        title: 'PHASE 2',
        desc: [
            {
                title: 'Scene 2 begins - ',
                content: 'each NFT holder will be asked to make a decision. Do you want to be airdropped the red pill or the blue pill? This decision is completely free to make, and will also determine the rarity of the red pill and blue pill! Based on this decision you will be able to burn this pill and receive a new NFT. This NFT will have the same traits your Misfits: Origins character has, but will be combined with the red pill or blue pill features. '
            },
            {
                title: 'Misfits Care Initiative #2 - ',
                content: 'We will donate to and work with organizations and agencies involved with the rehabilitation and resettlement of refugees all over the world. Ordinary citizens are forced to suffer from war and violence, and we will do our part in ensuring they find a new home and feel safe wherever they are. Misfits together... strong.'
            },
            {
                title: 'Early access to Solo Projects - ',
                content: 'Our two artists plan to launch their own limited supply, art-centric NFT collection. <br />This collection will not fall under the PFP category. <br />Holders of the Misfits Origins collection will be given early access to these collections before they go on public sale.'
            }
        ]
    },
    {
        title: 'PHASE 3',
        desc: [
            {
                title: 'Misfits Merchandise Store - ',
                content: 'Our aim is to establish Misfits as a popular and recognized brand in Web 3.0. In Phase 3 we launch our merchandise store with apparel that’s in line with our brand. The final look and style of the apparel will be decided by the DAO and a percentage of sales will be re-distributed to our holders.'
            },
            {
                title: 'Special Collaborations - ',
                content: 'The Misfits brand at its core is an art-centric project that aims to bring quality artwork to the Solana NFT space either through our own collection or through collaborations. We plan to collaborate with NFT artists and other high-quality NFT projects on Solana to bring out exciting new work that will push the boundaries of creativity in this space.'
            },
        ]
    },
    {
        title: 'PHASE 4',
        desc: [
            {
                title: 'Misfits Exhibitions - ',
                content: 'We will host a series of IRL art exhibitions, showcasing artwork from up-and-coming real-world artists, as well as artwork created by NFT artists from around the world. The Misfits Exhibition aims to merge the worlds of physical art and NFT art, and give more exposure to artists in both these spaces. A percentage of revenue from this venture will be distributed to holders of the Misfits Origins collection.'
            },
            {
                title: 'Incubator Program - ',
                content: 'New NFT collections, can apply to our Incubator Program. This program aims to assist and provide consultation to upcoming projects, provide funding, extend our resources and provide any other assistance for a successful launch of their NFT project. The decision on which NFT projects will be selected for the incubator program will be made by our DAO, and a percentage of profits from these projects will be re-distributed to holders of the Misfits Origins collection.'
            },
            {
                title: 'Announce Roadmap 2.0',
            },
        ]
    },
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img className={className} style={{...style, height: '5vh', width: '2vw', minWidth: 25, minHeight: 25, objectFit: 'contain'}} onClick={onClick} src='/assests/arrows/WAR.png'
      />
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className} style={{...style, height: '5vh', width: '2vw', minWidth: 25, minHeight: 25, objectFit: 'contain'}} onClick={onClick} src='/assests/arrows/WAL.png'
        />
    );
}

const Card = ({title, desc, count}) => (
    <CardWrapper>
        {/* <CardImg src={`/assests/roadmap/Phases Clean Placard.png`} /> */}
        <CardTitle>{title}</CardTitle>
        <CardDesc>
            {
                desc.map((data, index) => (
                        <li key={index}><span className='highlight'>{data.title}</span><span dangerouslySetInnerHTML={{__html: data?.content}}></span></li>
                ))
            }
        </CardDesc>
    </CardWrapper>
)

function Timeline() {
    const [center, setCenter] = useState(true)

    const sizeUpdate = (event) => {
        var newWidth = window.innerWidth;
        var newHeight = window.innerHeight; 
        if (newWidth <= 560) {
            return setCenter(false)
        }
        return setCenter(true)
    }

    useEffect(() => {
        sizeUpdate()
        window.addEventListener('resize', sizeUpdate);
    }, [])

    const settings = {
        className: "center",
        // centerMode: center,
        infinite: false,
        // centerPadding: "60px",
        // initialSlide: 1,
        slidesToShow: 3,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 834,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <Container id='ROADMAP'>
            <Title>Roadmap</Title>
            <Wrapper>
                <Slider {...settings}>
                    {
                        cardList.map((data, index) => <Card key={index} count={index+1} title={data.title} desc={data.desc}/>)
                    }
                </Slider>
            </Wrapper>
        </Container>
    )
}

export default Timeline

const Container = styled.section`
    min-height: 100vh;
    background-image: url('/assests/Roadmap BG_Desktop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 14vh 0;
    
    @media(max-width: 500px) {
        background-image: url('/assests/Roadmap BG_Mobile.png');
        padding-bottom: 2vh;
    }
`

const Title = styled.div`
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    /* font-size: 5.5vh; */
    font-size: 4.5vh;
    line-height: 8vh;
    color: #FFFFFF;
    margin-left: 40px;
`

const Wrapper = styled.div`
    padding: 2vh 0;
    padding-bottom: 0;

    .slick-slide {
        /* transform: scale(0.85); */
        transform: scale(0.92);
    }

    .slick-center  {
        /* transform: scale(1.0); */
    }
    .slick-prev {
        position: absolute;
        left: 2%;
        z-index: 1;
    }

    .slick-next {
        position: absolute;
        right: 2%;
        z-index: 1;
    }

    .slick-dots {
        position: absolute;
        bottom: 16px;
    }

    .slick-dots li button:before {
        color: white;
        opacity: 0.5;
    }
    
    .slick-dots li.slick-active button:before {
        color: white;
        opacity: 1;
    }

`

const CardWrapper = styled.div`
    position: relative;
    max-width: 33.5vw;
    margin: 0 1vw;
    min-height: 120vh;
    height: 100%;
    width: 100%;
    width: 100%;
    padding: 3.2vh 1.6vw;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 7.21127px;
    margin: auto;
    padding: 3.2vh 2.4vw;
    @media(max-width: 1024px) {
        margin: auto;
        min-width: 260px;
    }
    @media(max-width: 834px) {
        max-width: 80vw;
        padding: 32px 24px;
    }
    @media(max-width: 690px) {
        padding: 3.2vh 24px;
    }
`

const CardTitle = styled.div`
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    font-size: 4.5vh;
    line-height: 5vh;
    /* text-decoration-line: underline; */
    color: #FFFFFF;

`

const CardDesc = styled.ul`
    padding: 3.5vh 0;
    font-family: Helvetica;
    font-style: normal;
    font-size: 2.2vh;
    line-height: 3.2vh;
    padding-bottom: 0;
    color: #FFFFFF;

    li {
        padding-bottom: 5vh;
    }

    .highlight {
        font-weight: bold;
    }

`

const CardImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* opacity: 0.5; */
`