import React, { useEffect } from 'react'
import { Accordion } from 'responsive-react-accordion';
import styled from 'styled-components';

const FAQList = [
    {
        question: 'How many traits are there in the Misfits: Origins collection? Are they hand drawn?',
        answer: 'There are over 200 traits in the Misfits Origins collection. Yes, all the traits are hand drawn except for the background traits.'
    },
    {
        question: 'What is the mint price?',
        answer: `We have 3 stages of mint and the mint price for each stage is different. nwline
        dv 1) bld OG Sale Mint Price -blc 0.95 Solana dc
        dv 2) bld Whitelist Mint Price-blc 1.1 Solana dc
        dv 3) bld Public Mint Price -blc 1.35 Solana dc
        `
    },
    {
        question: 'When is the public mint date? Is there a presale as well?',
        answer: `
        dv 1) bld OG Sale: blc nwline Starts on 
        bld Sunday, 3rd April at 6pm GMT blc nwline 
        Mint Price during OG sale - bld 0.95 SOL blc nwline
        Total number of NFTs available during OG Sale - bld 690 NFTs blc nwline
        The OG Sale will be accessible by members who have the @OG Misfits role. There will be a total of 690 NFTs available to these members to mint. The OG sale will go on for 6 or till all 690 NFTs have been minted - whichever comes first. The balance NFTs if any, will get carried forward to the whitelist sale. 
        dc
        dv 2) bld Whitelist Sale: blc nwline Starts on Monday,  
        bld 4th April at 12am GMT blc nwline 
        Mint Price during Whitelist sale - bld 1.1 SOL blc nwline
        Total number of NFTs available during Whitelist Sale - bld 2,690 NFTs blc nwline
        The Whitelist Sale will be accessible to those who have the @Whitelisted role. There will be a total of 2,690 NFTs available to these members to mint. The whitelist sale will go on for 9 hours (Time yet to be announced), or if the 2,690 NFTs have been minted - whichever comes first. The balance NFTs if any, will get carried forward to the public sale.  
        dc
        dv 3) bld Public Sale: blc nwline 
        Starts on Monday, 4th April at 9am GMT nwline 
        Mint Price during Public sale - 1.35 SOL nwline
        The public sale will be accessible by anyone. Assuming that OG and Whitelist members mint all the NFTs available, there will be 1,160 NFTs available for mint during the public mint. If there is any balance remaining in the previous sales, then this will also get carried forward to the public sale. nwline
        dc 
        `
    },
    // {
    //     question: 'Is there a Whitelist?',
    //     answer: `There will be a total of 2,000 members on our Whitelist`
    // },
    {
        question: 'How do I secure a whitelist spot?',
        answer: `
        There are multiple ways to secure a spot on the whitelist! Our team doesn’t believe in "grinding" to secure a whitelist spot. We all have busy lives and we don’t expect you to be active 24/7 on our Discord channel. Instead, you could:
        dv 1) bld Have the OG Misfits Role: blc We have a total of 200 spots, with less than 30 spots up for grab. You can secure this role by being an individual that drives the Misfits: Origins project forward. OG Roles will can also be secured by through giveaways and participating in daily activities
        dc
        dv 2) bldDiscord Invite Contest: blc On the date before presale, the top 10 members who have the most number of invites on Discord will be rewarded. nwline
        Top 3 members with the most invites will each receive the @OG Misfits role and 1 NFT from the Misfits: Origins collection.nwline
        Remaining 7 members will each receive the @Whitelisted role. If you already have the Whitelist role then you will receive the @OG Misfits role. 
        dc
        dv 3) bld Participate in our Fan-Art Competition: blc For all you creative folks out there, submit your fan-art in our #fan-art channel. You could create your own version of the Misfits or even add a creative twist, but remember it should revolve around the Misfits Art!
        dc
        dv 4) bld Take part in our Meme Content: blc Think you could make us laugh? Post your memes in our #meme-contest channel! Remember to incorporate the Misfits characters into your memes for bonus points
        dc
        dv 5) bld Attend our Daily Activities: blc In our leading day’s up to mint, we will be holding daily activities – @OG Roles and @WL Roles are up for grabs for every activity! nwline
        Test your knowledge at our Quiz Night, Jam to some songs during our Guess the Music, Warm up your fingers with our Gartic Guess the Drawing or Gamble your chance to win a Misfits NFT at our Poker Night!        
        dc
        dv 6) bld Lookout for Giveaways: blc Stay alert as we’ll be hosting a series of giveaways on our Discord server and Twitter account. Keep participating and you may get lucky! 
        dc
        dv 7) Last, and most importantly, stay kind, helpful and spread positive vibes! - Our team is always on the lookout for community members who help the Misfits Family grow. You will be noticed – Kindness goes a long way in this community 💜
        dc
        `
    },
    {
        question: 'How many NFTs are in the collection?',
        answer: `There are a total of 4,690 NFTs in the Misfits: Origins collection.`
    },
    {
        question: 'What is a DAO? How does it work?',
        answer: `DAO stands for a Decentralized Autonomous Organization. Each NFT owned by a member represents one vote in the future decision-making process and to vote on the proposals put forward by its members. The DAO will be responsible for selecting the charities we contribute to, finalizing the design and final look of our merchandise, and selecting the NFT projects to accept into our incubator program.`
    },
    // {
    //     question: 'How many NFTs can I mint per wallet?',
    //     answer: `During presale/whitelist sale - each wallet can mint up to 3 NFTs.
    //         During public sale - each wallet can mint up to 10 NFTs.`
    // },
]

const openIcon = () => (
    <img className='icon' src='/assests/open.png' />
)

const closeIcon = () => (
    <img className='icon' src='/assests/close.png' />
)

function FAQ() {


    useEffect(() => {
        var accordion = document.getElementsByClassName('accordion-collapsible')
        // accordion.forEach(editInnerHTML)
        for (var i=0; i<= accordion.length; i++){
            const temp = (accordion[i]?.innerHTML)
            if(temp){
                var data = temp.replace(/nwline/g, '<br />')
                accordion[i].innerHTML = data
            }
        }
        var accordion = document.getElementsByClassName('accordion-collapsible')
        // accordion.forEach(editInnerHTML)
        for (var i=0; i<= accordion.length; i++){
            const temp = (accordion[i]?.innerHTML)
            if(temp){
                var data = temp.replace(/bld/g, '<b>')
                var data = data.replace(/blc/g, '</b>')
                var data = data.replace(/dv/g, '<div>')
                var data = data.replace(/dc/g, '</div>')
                accordion[i].innerHTML = data
            }
        }
        // console.log(accordion)
    }, [])

  return (
    <Container id='FAQ'>
        <Title>Common Questions</Title>
        <Wrapper>
            {
                FAQList.map((faq, index) => <Accordion 
                key={index}
                title={faq.question} 
                content={faq.answer} 
                openIcon={openIcon()}
                closeIcon={closeIcon()}
            />)
            }
        </Wrapper>
    </Container>
  )
}

export default FAQ

const Container = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    background-image: url('/assests/FAQ BG_Desktop.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 6vh 0;
    @media(max-width: 834px) {
        padding: 48px 24px;
    }
`

const Title = styled.div`
    text-align: center;
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    /* font-size: 5.5vh; */
    font-size: 4.5vh;
    line-height: 8vh;
    color: #FFFFFF;
    margin: 3vh 0;
`

const Wrapper = styled.div`
    margin: 9vh 0;
    margin-top: 4.5vh;
    color: white;

    .icon {
        width: 1vw;
        min-width: 20px;
    }

    .accordion {
        max-width: 70vw;
        border-top: 0.3vh solid #46A8C2;
        border-radius: 0;
        border-collapse: collapse;
    }
    
    .accordion-container:last-child .accordion {
        border-bottom: 0.3vh solid #46A8C2;
    }

    .accordion-title {
        padding: 2vh 1vw;
        font-size: 2.4vh !important;

    }

    .accordion-active {
        padding: 2vh 1vw;
        padding-top: 0px;
        padding-bottom: 3vh;

    }

    .accordion-collapsible {
        padding-left: 1vw;
        padding-right: 1vw;
        font-size: 2.0vh !important;
        flex-direction: column;

    }

    @media(max-width: 460px) {
        .accordion {
            max-width: auto;
        }
        .accordion-title, .accordion-collapsible {
            font-size: 14px !important;
        }
    }
`