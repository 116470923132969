import React, { useState } from 'react'
import styled from 'styled-components'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const cartoonList = [
    'nihal', 'sabby', 'akhila', 'valerie', 'hassan'
]

const cardList = [
    {
        name: 'Nihal - Founder and CEO',
        image: 'Nihal',
        desc: "Our very own visionary leader. Nihal steers this team with his deep understanding of the crypto and NFT space, along with his IRL experience in investment banking and finance. He will oversee operations, execution of the roadmap as per timelines, and work towards the long-term brand and vision of this project."
    },
    {
        name: 'Sabby - Founder and CMO',
        image: 'Sabby',
        desc: "Known to be an out-of-the-box-thinker, Sabby is our head of marketing. With years of experience in sales and marketing, he will be responsible for driving our online presence and reach, securing collaborations with other NFT projects, and creating long-term connections with the NFT community."
    },
    {
        name: 'Akhila - Founder and Art Director',
        image: 'Akhila',
        desc: "An artist with a surreal imagination, Akhila will be unveiling a glimpse of her creativity through this project and bringing the Misfits to life. A designer by profession, she currently runs her own interior décor fabrication business and has experience with sketching, paintings - in both traditional and digital mediums, as well as 3D modeling."
    },
    {
        name: 'Valerie - Founder and Creative Director',
        image: 'Valerie',
        desc: "With experience in digital content creation, video editing, and animation, Valerie is our Head of Creative. She will be involved in finalizing the look and feel of the artwork, creating our promotional videos and digital marketing material based on our NFT character’s storyline."
    },
    {
        name: 'Hassan - Founder and CFO',
        image: 'Hassan',
        desc: "Hassan brings to the team his financial knowledge and understanding of business. He will handle our project’s finances, budgeting, will ensure that operations are being executed as per schedule, and work towards the implementation of the roadmap."
    },
]

const Card = ({name, img, desc}) => (
    <CardWrapper>
        <Image src={`/assests/People/${img}.png`} />
        <Name>{name}</Name>
        <Desc>{desc}</Desc>
    </CardWrapper>
)

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <img className={className} style={{...style, height: '5vh', width: '2vw', minWidth: 25, minHeight: 25, objectFit: 'contain'}} onClick={onClick} src='/assests/arrows/AR.png'
      />
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <img className={className} style={{...style, height: '5vh', width: '2vw', minWidth: 25, minHeight: 25, objectFit: 'contain'}} onClick={onClick} src='/assests/arrows/AL.png'
        />
    );
}

function People() {

    const [active, setActive] = useState(0)
    
    const onUpdate = (index) => {
        setActive(index)
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplaySpeed: 8000,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: onUpdate,
        // appendDots: dots => (
        //     <div
        //       style={{
        //         backgroundColor: "#ddd",
        //         borderRadius: "10px",
        //         padding: "10px"
        //       }}
        //     >
        //         {console.log(dits)}
        //       <ul style={{ margin: "0px" }}> {dots} </ul>
        //     </div>)
    };

    return (
        <Container id='TEAM'>
            <Title>TEAM</Title>
            <Wrapper>
                <Slider {...settings}>
                    {
                        cardList.map((item, index) => <Card name={item.name} img={item.image} desc={item.desc} />)
                    }
                </Slider>
            </Wrapper>
            <Cartoon>
                {
                    cartoonList.map((item, index) => <CartonImg active={index === active} key={index} src={`/assests/People/cartoon/${item}.png`} />)
                }
            </Cartoon>
        </Container>
    )
}

export default People

const Container = styled.section`
    min-height: 60vh;
    background-image: url('/assests/people-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 14vh;

`

const Title = styled.div`
    text-align: center;
    font-family: Bison;
    font-style: normal;
    font-weight: bold;
    /* font-size: 5.5vh; */
    font-size: 4.5vh;
    line-height: 8vh;
    color: #FFFFFF;
`

const Wrapper = styled.div`
    padding: 6vh 0;
    padding-bottom: 0;

    .slick-prev {
        position: absolute;
        left: 20%;
        z-index: 1;
    }

    .slick-next {
        position: absolute;
        right: 20%;
        z-index: 1;
    }

    .slick-dots {
        position: absolute;
        bottom: -6vh;
    }

    .slick-dots li button:before {
        color: white;
        opacity: 0.5;
    }
    
    .slick-dots li.slick-active button:before {
        color: white;
        opacity: 1;
    }
    @media(max-width: 690px) {
        .slick-prev {
            left: 4%;
        }

        .slick-next {
            right: 4%;
        }
    }
    @media(max-width: 560px) {
        .slick-dots {
            bottom: -10px;
            display: none !important;
        }
    }

`

const CardWrapper = styled.div`
    max-width: 41vw;
    width: 100%;
    padding: 3.2vh 1.6vw;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 7.21127px;
    margin: auto;
    @media(max-width: 690px) {
        min-height: 320px;
        max-width: 75vw;
        min-width: 260px;
        padding: 3.2vh 16px;
    }
`

const Image = styled.img`
    margin: auto;
    max-width: 8vw;
    max-height: 8vw;
    min-height: 80px;
    min-width: 80px;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Name = styled.div`
    font-family: Helvetica;
    font-style: normal;
    font-weight: bold;
    font-size: 2.4vh;
    line-height: 3.4vh;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;
    padding: 1.6vh 0;
    text-align: center;
    @media(max-width: 560px) {
        font-size: 20px;
    }
`

const Desc = styled.div`
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 2.2vh;
    line-height: 3.2vh;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding: 1vh 1vw;
    padding-bottom: 1.6vh;
    @media(max-width: 690px) {
        padding: 6px 2px;
    }
    @media(max-width: 560px) {
        font-size: 16px;
    }
`

const Cartoon = styled.div`
    display: flex;
    overflow-x: hidden;
    margin-top: 4vh;
    @media(max-width: 560px) {
        margin-top: -10px;
    }
`

const CartonImg = styled.img`
    max-width: 50vw;
    max-height: 40vh;
    width: 100%;
    height: 100%;
    min-width: 200px;
    min-height: 200px;
    object-fit: contain;
    filter: ${props => props.active ? '' : 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))' } ;
    mix-blend-mode: ${props => props.active ? '' : 'luminosity' } ;
    opacity: ${props => props.active ? '' : 0.85 };
    transform: ${props => props.active ? 'scale(1.4) translateY(-14.5%)' : ''};
    /* transform: ${props => props.active ? 'translateY(10px)' : ''}; */

    :first-child {
        margin-left: -4%;
    }
    :nth-child(n+2) {
        margin-left: -8%;
    }

    @media(max-width: 740px) {
        display: ${props => props.active ? '' : 'none'};
        max-width: 260px;
    }
`