import React from 'react'
import styled from 'styled-components'

function WhyMisFits() {
  return (
    <Container id='OUR AIM'>
        <img className='horiz' src="/assests/whymisfits-horiz.png" alt="" />
        <Wrapper>
            <Title><img className='vert' src="/assests/Whymisfits.png" alt="" /></Title>
            <Content>
                <h1>OUR AIM</h1>
                <Detail>
                    <p>
                        The theme of the artwork represents a feeling of being out of place. An experience that we have all felt at some point in our lives, or continue to feel to this day. 
                    </p>
                    <p>
                        Our aim is to deliver high-quality artwork. We wish to create a community that is not just inclusive, but one which allows all our members to learn and grow as a collective, as we navigate our way through the ever-changing landscape of Web 3.0. 
                    </p>
                    <p>
                        The Misfits identity intends to cement itself as a leading brand in Web 3.0, known for delivering high-quality artwork and pushing the limits of creativity in this space - through the Misfits Origins collection and from collaborations with other NFT artists and NFT projects.
                    </p>
                </Detail>
            </Content>
            <Image>
                <img src="/assests/dot.png" alt="" className="dot" />
                <div className="mockup-container">
                    <img src="/assests/Our Aim NFT.png" alt="" className='mockup' />
                    <div className="box"></div>
                </div>
            </Image>
        </Wrapper>
    </Container>
  )
}

export default WhyMisFits

const Container = styled.section`
    min-height: 100vh;
    background: #1A1A1A;
    overflow-x: hidden;
    padding-top: 12vh;

    
    .horiz {
        display: none;
    }

    @media(max-width: 690px) {
        padding: 12vh 0;
        .vert {
            display: none;
        }
        .horiz {
            display: flex;
            margin: auto;
            max-width: 200px;
            width: 100%;
        }
    }
`

const Wrapper = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    /* align-items: center; */
    justify-content: space-between;
    padding: 0 3vw;

    @media(max-width: 690px) {
        flex-direction: column-reverse;
        justify-content: center;
    }
`

const Title = styled.div`
    width: 15vw;
    img {
        width: 100%;
    }

`

const Content = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
        font-family: Bison;
        font-style: normal;
        font-weight: bold;
        /* font-size: 5.5vh; */
        font-size: 4.5vh;
        line-height: 8vh;
        color: #FFFFFF;
    }
    @media(max-width: 690px) {
        align-items: flex-start;
        margin: 0 24px;
    }

`

const Detail = styled.div`
    max-width: 24vw;
    width: 100%;
    p {
        margin: 2vh 0 ;
        color: white;
        font-size: 2.4vh;
    }
    @media(max-width: 690px) {
        max-width: 80vw;
    }

`

const Image = styled.div`
    position: relative;
    .dot {
        position: absolute;
        top: 0;
        right: -20px;
        width: 30vw;
    }

    .mockup-container {
        width: 39vw;
        height: 80vh;
        position: relative;
        margin: 10vh 0;
    }

    .mockup {
        width: 100%;
        height: 100%;
        z-index: 10;
        object-fit: contain;
    }

    @media(max-width: 960px) {
        .mockup-container {
            margin: 1vh 0;
        }
    }

    @media(max-width: 690px) {
        margin: 32px 0;
        .dot {
            width: 60vw;
        }
        .mockup-container {
            margin: 16px auto;
            width: 60vw;
            height: auto;
        }
    }
    @media(max-width: 390px) {
        .mockup-container {
            width: 80vw;
        }
    }

`