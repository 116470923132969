import React from 'react'
import styled from 'styled-components'

function BackToTop() {
  return (
    <Container>
        <img onClick={() => {window.scrollTo(0, 0)}} src="/assests/arrows/AT.png" alt="Back To Top" />
    </Container>
  )
}

export default BackToTop

const Container = styled.div`
    position: fixed;
    z-index:2;
    bottom: 20px;
    right: 20px;

    img {
        width: 3vw;
        min-width: 50px;
        cursor: pointer;
        z-index: 2000 !important;
    }

    @media(max-width: 560px) {
      img {
        min-width: 36px;
      }
    }
`