import styled from 'styled-components';
import './App.css';
import BackToTop from './components/BackToTop';
import Header from './components/Header';
import FAQ from './section/FAQ';
import Footer from './section/Footer';
import Origin from './section/Origin';
import Origin2 from './section/Origin2';
import People from './section/People';
import Timeline from './section/Timeline';
import Video from './section/Video';
import WhyMisFits from './section/WhyMisFits';

function App() {
  return (
    <Container>
      <BackToTop />
      <Header />
      <Origin />
      <Origin2 />
      <Video />
      <WhyMisFits />
      <Timeline />
      <People />
      <FAQ />
      <Footer />
    </Container>
  );
}

export default App;

const Container = styled.div`
  background-color: black;
`