import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slide2 = () => (
  <SlideContainer>
    <Img>
      <img src="/assests/01_Landing.png" alt="" />
    </Img>
    <Left>
      <Heading>
        ITS TIME TO
        <br />
        <span className="main-heading">MINT!</span>
      </Heading>
      <Desc>
        The time has come to meet your unique Misfit from Terra-Nebula
      </Desc>
      <Mint>Mint</Mint>
    </Left>
    <Right>
      <Heading>
        JOIN OUR GROUP OF
        <br />
        <span className="main-heading">MISFITS</span>
      </Heading>
      <Desc>
        Get involved with our community on Twitter and Discord - Misfits from
        all walks of life are welcome!
      </Desc>
      <a href="#" target={"_blank"}>
        <img src="/assests/DiscordCTA.png" alt="" />
      </a>
      <a href="#" target={"_blank"}>
        <img src="/assests/TwitterCTA.png" alt="" />
      </a>
    </Right>
  </SlideContainer>
);

const Slide2_1 = () => (
  <Slide1Container>
    <img src="/assests/Origin/1_2.png" alt="" />
    <Text>
      <div>
        Make sure to join our community on Twitter and Discord. <br />
        Misfits from all walks of life are welcome
      </div>
    </Text>
    <ButtonWrapper>
      <a target={"_blank"} href="https://discord.gg/FVeUz9M67m">
        <img src="/assests/Origin/Dis.png" alt="" />
      </a>
      <a target={"_blank"} href="https://twitter.com/MisfitsOrigins">
        <img src="/assests/Origin/Twt.png" alt="" />
      </a>
    </ButtonWrapper>
  </Slide1Container>
);

const Slide1 = () => (
  <SlideContainer style={{ position: "relative" }}>
    <img
      className="big-screen"
      src="/assests/Origin/Desktop Banner.gif"
      alt=""
    />
    <a href="https://mint.misfitsworld.com/">
      <img
        className="btn"
        src="/assests/Origin/Mint Now Button (1).png"
        alt=""
      />
    </a>
    <img
      className="small-screen"
      src="/assests/Origin/Mobile Banner.gif"
      alt=""
    />
  </SlideContainer>
);

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{
        ...style,
        height: "5vh",
        width: "2vw",
        minWidth: "25",
        minHeight: "25",
        objectFit: "contain",
      }}
      onClick={onClick}
      src="/assests/arrows/AR.png"
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{
        ...style,
        height: "5vh",
        width: "2vw",
        minWidth: "25",
        minHeight: "25",
        objectFit: "contain",
      }}
      onClick={onClick}
      src="/assests/arrows/AL.png"
    />
  );
}

function Origin() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: false,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Container>
      <Slider {...settings}>
        <Slide1 />
        <Slide2_1 />
      </Slider>
    </Container>
  );
}

export default Origin;

const Container = styled.section`
  img {
    height: 100%;
    max-height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .slick-prev {
    position: absolute;
    top: 40%;
    left: 30px;
    z-index: 1;
  }

  .slick-next {
    position: absolute;
    top: 40%;
    right: 30px;
    z-index: 1;
  }

  .slick-dots {
    position: absolute;
    bottom: 32px;
  }

  .slick-dots li button:before {
    color: white;
    opacity: 0.5;
  }

  .slick-dots li.slick-active button:before {
    color: white;
    opacity: 1;
  }
  @media (max-width: 500px) {
    .slick-prev {
      position: absolute;
      left: 2%;
      z-index: 1;
    }

    .slick-next {
      position: absolute;
      right: 2%;
      z-index: 1;
    }
  }
`;

const Slide1Container = styled.div`
  background-image: url("/assests/Origin/1_1.png");
  min-height: 90vh;
  /* width: 100vw; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  img {
    max-width: 50vw;
    margin: auto;
    padding-top: 20vh;
    position: relative;
  }

  @media (max-width: 500px) {
    img {
      max-width: 80vw;
      padding-top: 36vh;
    }
  }
`;

const Text = styled.div`
  /* position: absolute; */
  /* bottom: 26vh; */
  font-size: 2.8vh;
  width: 100%;
  /* transform: translate(50%, -50%); */
  margin: 0 auto;
  text-align: center;
  color: white;
  div {
    max-width: 55vw;
    min-width: 300px;
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 12vw;
    min-width: 200px;
    padding-top: 4vh;
    margin: 0;
    transition: opacity 300ms ease-in-out;
    cursor: pointer;
    :hover {
      opacity: 0.6;
    }
  }

  img:first-child {
    margin-right: 1vw;
  }
  img:nth-child(1) {
    margin-left: 1vw;
  }

  @media (max-width: 500px) {
    flex-direction: column !important;
    align-items: center;
    img {
      padding-top: 5px;
    }
    img:first-child {
      padding-top: 14px;
      margin-right: 0vw;
    }
  }
`;

const SlideContainer = styled.div`
  background: black;
  min-height: 100vh;
  position: relative;
  display: flex;

  justify-content: space-between;
  /* padding: 10vh 8vw; */

  .small-screen {
    display: none;
    width: 100%;
  }
  .big-screen {
    position: relative;
  }
  .btn {
    position: absolute;
    width: 400px;
    height: auto;
    top: 70%;
    left: 75%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  @media (max-width: 500px) {
    .small-screen {
      display: inline-flex;
    }
    .big-screen {
      display: none;
    }
  }

  @media (max-width: 760px) {
    min-height: 80vh;
    .btn {
      position: absolute;
      width: 200px;
      height: auto;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  @media (max-width: 1200px) {
    min-height: 80vh;
    .btn {
      position: absolute;
      width: 200px;
      height: auto;
      top: 320px;
      right: 200px;
      //   transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

const Img = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);

  img {
    max-height: 100% !important;
    /* max-width: 40vw !important; */
    height: 100%;
    width: 46vw;

    object-fit: contain;
  }

  @media (max-width: 560px) {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  font-family: Bison;
  font-style: normal;
  font-weight: bold;
  font-size: 8vh;
  line-height: 8.5vh;
  color: white;
  text-align: center;
  .main-heading {
    font-size: 15.4vh;
  }
  @media (max-width: 560px) {
    font-size: 26px;
    line-height: 34px;

    .main-heading {
      font-size: 42px;
    }
  }
`;

const Desc = styled.div`
  padding: 2vh 0;
  font-family: Helvetica;
  font-style: normal;
  font-size: 2.4vh;
  line-height: 3.2vh;
  color: white;
  text-align: center;
  max-width: 20vw;
  @media (max-width: 560px) {
    max-width: 300px;
    font-size: 20px;
  }
`;

const Mint = styled.div`
  background-color: #e70a77;
  max-width: 16vw;
  width: 100%;
  height: 6.5vh;
  text-align: center;
  border-radius: 6px;
  color: white;
  font-size: 4vh;
  font-family: Bison;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 560px) {
    max-width: 260px;
    height: 50px;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  a {
    margin-bottom: 6px;
  }

  img {
    max-width: 20vw;
    width: 100%;
    height: 6.5vh;
    object-fit: contain;
  }

  @media (max-width: 560px) {
    margin-top: 32px;
    img {
      max-width: 260px;
      height: 50px;
      min-width: 190px;
    }
  }
`;
