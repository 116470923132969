import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MenuIcon } from "@heroicons/react/solid";

function Header() {
  const [active, setActive] = useState("OUR ORIGIN");
  const [show, setShow] = useState(false);
  const navUpdate = () => {
    let current = "";
    const sections = document.querySelectorAll("section");
    const { pageYOffset } = window;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionTop - sectionHeight / 4) {
        current = section.getAttribute("id");
        setActive(current);
      }
    });
  };

  useEffect(() => {
    navUpdate();
    window.addEventListener("scroll", navUpdate);
    return () => {
      window.removeEventListener("scroll", navUpdate);
    };
  }, []);
  return (
    <Container>
      <Logo href="#">
        <img src="/assests/NewLogo.png" alt="" />
      </Logo>
      <MenuIcon
        onClick={() => setShow(!show)}
        className={`hamburger ${show && "active"}`}
      />
      <MainMenu show={show}>
        <Menu>
          <a
            onClick={() => setShow(!show)}
            className={`${active === "ORIGINS" && "active"}`}
            href="#ORIGINS"
          >
            ORIGINS
          </a>
          <a
            onClick={() => setShow(!show)}
            className={`${active === "OUR AIM" && "active"}`}
            href="#OUR AIM"
          >
            OUR AIM
          </a>
          <a
            onClick={() => setShow(!show)}
            className={`${active === "ROADMAP" && "active"}`}
            href="#ROADMAP"
          >
            ROADMAP
          </a>
          <a
            onClick={() => setShow(!show)}
            className={`${active === "TEAM" && "active"}`}
            href="#TEAM"
          >
            TEAM
          </a>
          <a
            onClick={() => setShow(!show)}
            className={`${active === "FAQ" && "active"}`}
            href="#FAQ"
          >
            FAQ
          </a>
          {/* <a onClick={() => setShow(!show)} target={'_blank'} href="/pdf/Whitepaper.pdf">WHITEPAPER</a> */}
          <a
            onClick={() => setShow(!show)}
            target={"_blank"}
            href="https://drive.google.com/file/d/1nPPUraQFQgZoBqQp0Zr66zGWSdqzvWIr/view"
          >
            WHITEPAPER
          </a>
          <a
            className="mint"
            onClick={() => setShow(!show)}
            href="https://mint.misfitsworld.com/"
          >
            MINT
          </a>
        </Menu>
        <div className="divider" />
        <div className="social-media">
          <div className="heading">FOLLOW US ON</div>
        </div>
        <SocialMedia>
          <a href="https://discord.gg/FVeUz9M67m" target={"_blank"}>
            <img src="/assests/Discord.png" alt="Discord" />
          </a>
          <a href="https://twitter.com/MisfitsOrigins" target={"_blank"}>
            <img src="/assests/Twitter.png" alt="Twitter" />
          </a>
        </SocialMedia>
      </MainMenu>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  min-height: 80px;
  /* height: 10vh; */
  align-items: center;
  background: #1a1a1a;
  padding: 0 1vw;
  z-index: 1000;

  .hamburger {
    display: none;
    width: 40px;
    color: white;
  }

  .active {
    color: #ffdc00;
  }

  @media (max-width: 960px) {
    .hamburger {
      display: flex;
    }
  }
`;

const Logo = styled.a`
  img {
    height: 8vh;
  }
`;

const MainMenu = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1vw;

  .divider,
  .social-media {
    display: none;
  }

  @media (max-width: 960px) {
    position: fixed;
    top: 80px;
    right: 0;
    bottom: 0;
    background: #1a1a1a;
    flex-direction: column;
    margin: 0;
    width: 300px;
    justify-content: flex-start;
    height: 100vh;
    /* overflow-y: scroll; */
    transform: ${(props) =>
      props.show ? "translateX(0)" : "translateX(100%)"};
    transition: transform 400ms ease-in-out;

    .divider {
      display: flex;
      height: 2px;
      width: 100%;
      background-color: #ffdc00;
    }

    .social-media {
      display: block;
    }

    .social-media .heading {
      padding: 24px 0;
      font-family: Bison;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      position: relative;
    }
    .social-media .heading::before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: auto;
      width: 100px;
      height: 1px;
      background-color: #ffdc00;
    }
  }
`;

const Menu = styled.div`
  font-family: Bison;
  font-style: normal;
  font-weight: bold;
  font-size: 4.5vh;
  /* line-height: 36px;s */
  color: #ffffff;
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: inherit;
    margin: 0 2.4vw;
    display: flex;
  }

  a:hover,
  .active {
    color: #ffdc00;
  }

  .mint {
    background-color: #e70a77;
    margin: 0 0.4vw;
    padding: 0.2vh 2.4vw;
    text-align: center;
    border-radius: 8px;
    // display: none;
  }
  .mint:hover {
    color: white;
    opacity: 0.6;
    transition: opacity 200ms ease-in-out;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 8px 8px;
    padding-bottom: 16px;

    a {
      margin: 8px 24px !important;
    }
    /* .mint {
            margin: 0;
        } */
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
  }
  a:first-child {
    margin-right: 1vw;
  }

  a img {
    min-width: 24px;
    width: 2.4vw;
  }

  a img:hover {
    opacity: 0.6;
    transition: opacity 200ms ease-in-out;
  }

  @media (max-width: 960px) {
    justify-content: center;
  }
`;
