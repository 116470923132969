import React from 'react'
import styled from 'styled-components'

function Origin2() {
  return (
    <Container  id='ORIGINS'>
        <Content>
            <h1>ORIGINS</h1>
            <p>
                Confused. Unsettled. Displaced. This is life for those who never really fit in - the Misfits. A group of unique, 2-dimensional characters that belong to a 3-dimensional world, known as Terra-Nebula.
            </p>
            <p>
                All the inhabitants of Terra-Nebula are 2-dimensional beings and seem to get along just fine with their lives - all except the Misfits. These Misfits truly believe that the world they live in is not meant for them. They grow confused and frustrated, unable to understand why no one sees or even feels the way they do.
            </p>
            <p>
                The Misfits soon accept their fate and begin to try and fit in - they figure it is much easier to change who they are rather than change the world around them.
            </p>
            <p>
                As the Misfits embark on this identity change to fit into their world, suddenly - almost as if fate was toying with them - the Misfits receive a mysterious package. Little do they know this package is about to dramatically change their entire world.
            </p>
            <p className='end'>
                ... End of Scene 1
            </p>
        </Content>
    </Container>
  )
}

export default Origin2

const Container = styled.section`
    min-height: 120vh;
    width: 100%;
    background-image: url('/assests/Universe2.png');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 0 7vw;
    justify-content: center;
    @media(max-width: 680px) {
        background-image: url('/assests/UniverseMob.png');
    }
`

const Content = styled.div`
    margin-top: 6%;
    padding: 2.4vh 2.4vw;
    margin-left: auto;
    border: 0.6vh solid #FFFFFF;
    box-sizing: border-box;
    color: white;
    position: relative;
    max-width: 42vw;
    width: 100%;
    background: linear-gradient(270deg, #1A1A1A -0.04%, rgba(26, 26, 26, 0.77) 72.94%, rgba(26, 26, 26, 0) 103.52%);

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        top: -4.5vh;
        left: -3vw;
        right: 0;
        bottom: 0;
        position: absolute;
        border: 0.6vh solid #FFFFFF;
        /* filter: drop-shadow(0px 4px 4px #FFFFFF); */
    }

    h1 {
        font-family: Bison;
        font-style: normal;
        font-weight: bold;
        font-size: 4.5vh;
        /* line-height: 8vh; */
    }

    p {
        font-family: Helvetica;
        font-style: normal;
        font-weight: normal;
        font-size: 2.4vh;
        line-height: 3vh; 
        margin: 16px 0;
        max-width: 34vw;
    }
    
    p:last-child {
        margin-bottom: 5vh;
    }

    .end {
        font-weight: bold;
    }

    
    @media(max-width: 960px) {
        max-width: 60vw;
    }

    @media(max-width: 680px) {
        max-width: 80vw;    
        p {
            max-width: 70vw;
        }
    }
    
    @media(max-width: 680px) {
        max-width: 90vw;  
        border: none;
        background: transparent;
        &::before {
            display: none;
        }
        p {
            max-width: 90vw;

        }
    }

`